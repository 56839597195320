import { Cliente } from "app/models/cliente.models";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ClientesService } from "app/services/clientes.service";
import { MatDialog } from "@angular/material/dialog";
import { DetalleClienteComponent } from "./detalle-cliente/detalle-cliente.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import Swal from "sweetalert2";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Accion, Acciones, MatColumn } from "app/shared/tabla-responsiva/tabla-responsiva.component";
import { Router } from "@angular/router";
import { DialogLoadingComponent } from "../dialog-loading/dialog-loading.component";
import { ArchivosService } from "app/services/archivos.service";
import { DataService } from "app/services/data.service";
import { UploadFileComponent } from "app/shared/upload-file/upload-file.component";

@Component({
  selector: "app-clientes",
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.component.css"],
})
export class ClientesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['numero', 'razonsocial', 'nrodocumento', 'tipo', 'accion'];
  dataSource = new MatTableDataSource<any>([]);
  paginaSiguiente = 1;
  totalArticulos = 10;
  itemXPag = 10;
  search = "";
  lastKeypress: any;
  loading: boolean;
  clientes: Cliente[] = [];

  constructor(
    private clienteService: ClientesService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public router: Router,
    public dataService: DataService,
    private archivoServicio: ArchivosService
  ) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Clientes y Proveedores';
    }
    this.getClientes();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  recibirAccion(accion) {
    switch (accion.tipo) {
      case Accion.redirect:
        this.router.navigate(['/clientes/resumen/', accion.dato._id])
        break;
      case Accion.edit:
        this.router.navigate(['/clientes/edit/', accion.dato._id])
        break;
      case Accion.delete:
        this.eliminar(accion.dato)
        break;
      default:
        break;
    }
  }

  pageEvent(event) {
    this.paginaSiguiente = event + 1;
    this.getClientes();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search = filterValue.trim().toLowerCase();
    if (event.timeStamp - this.lastKeypress > 100) {
      this.paginaSiguiente = 1;
      this.getClientes();
    }
    this.lastKeypress = event.timeStamp;
  }

  getClientes() {
    this.clientes = [];
    this.dataSource.data = [];
    this.loading = true;
    this.clienteService.getClientes(this.paginaSiguiente, this.itemXPag, this.search).subscribe(
      (res) => {
        this.clientes = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        //this.dataSource.paginator = this.paginator;
        this.totalArticulos = res.total;
        this.loading = false;
      },
      err => {
        this.openSnackBar(`Ocurrió un error al obtener listado clientes`, 'Información');
        this.loading = false;
      }
    );
  }

  openDialog(cliente?: Cliente) {
    const dialogRef = this.dialog.open(DetalleClienteComponent, {
      minWidth: '50%',
      data: { numero: this.clientes.length + 1, cliente },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getClientes();
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  page(event) {
    if (event.pageIndex == this.paginaSiguiente) {
      this.paginaSiguiente = event.pageIndex + 1;
    } else {
      this.paginaSiguiente = this.paginaSiguiente - 1;
    }
    this.getClientes();
  }

  /**
   * Descargar un excel con los articulos que tiene el sistema
   */
  descargarExcel() {
    const fileName = `clientes_${new Date().getDate()}${new Date().getUTCMonth() + 1}${new Date().getFullYear()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}.xlsx`;
    this.dataService.isCloseDialog.next(true);
    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      minWidth: '400',
      minHeight: '400',
      disableClose: true,
      data: {
        mensaje: 'Descargando listado de clientes. Espere ...'
      }
    });

   this.archivoServicio.getClientesExcel().subscribe(
      (response) => {
        this.dataService.isCloseDialog.next(false);
        this.manageExcelFile(response, fileName)
      },
      (err) => {
        this.dataService.isCloseDialog.next(false);
        console.log(err)
        this.openSnackBar(`Ocurrió un error al descargar listado clientes`, 'Información');
      }
    )
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      
    });
  }

  manageExcelFile(response: any, fileName: string): void {
    debugger;
    const dataType = response.type;
    const binaryData = [];
    binaryData.push(response);

    const filtePath = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    const downloadLink = document.createElement('a');
    downloadLink.href = filtePath;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  cargarExcel() {
    const dialogRef = this.dialog.open(UploadFileComponent, {
      minWidth: '400',
      minHeight: '400',
      disableClose: true,
      data: {
        mensaje: 'Subir un archivo excel con los clientes y proveedores',
        tipo: 'clientes'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.getClientes();
    });
  }

  eliminar(cliente: Cliente) {
    Swal.fire({
      title: '¿Desea eliminar el cliente?',
      text: `${cliente.razonsocial}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.clienteService.eliminarCliente(cliente._id).subscribe(
          (data) => {
            this.clientes = data;
            this.getClientes();
            this.openSnackBar(`${cliente.tipo} eliminado correctamente`, 'Información');
          },
          (err) => {
            console.log(err);
            this.openSnackBar(`Error al eliminar el cliente`, 'Información');
          }
        );
      }
    })
  }
}
